<template>
  <v-card class="w-100" @click="goToDetails">
    <v-card-title
      class="d-flex flex-row"
      :class="$vuetify.breakpoint.xs ? '' : 'justify-between'"
    >
      <div
        style="width: 300px"
        class="break-word flex-grow"
        :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'"
      >
        {{ blog.title }}
      </div>
      <div>
        <v-btn
          icon
          v-if="profile._id == blog.poster"
          @click.stop="editBlog"
          @mousedown.stop
          @touchstart.stop
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <v-btn
        icon
        v-if="profile._id == blog.poster"
        @click.stop="onDeleteBlog"
        @mousedown.stop
        @touchstart.stop
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle class="mt-0 d-flex">
      <editable-avatar
        :image="blog.poster_image"
        :editable="false"
        :size="$vuetify.breakpoint.xs ? 30 : 40"
      />
      <div>
        <div
          class="ml-3 url-button font-bold"
          :class="$vuetify.breakpoint.xs ? 'font-14' : 'font-16'"
          @click.stop="goToAgencyProfile(blog.poster)"
          @mousedown.stop
          @touchstart.stop
        >
          {{ blog.poster_name }}
        </div>
        <span
          class="gray-font-color ml-3"
          :class="$vuetify.breakpoint.xs ? 'font-8' : 'font-10'"
          v-text="getTimeAgo(blog.createdAt)"
        />
      </div>
    </v-card-subtitle>
    <v-card-subtitle class="d-flex flex-row">
      <div
        :style="
          $vuetify.breakpoint.xs
            ? 'width: 60px; height: 60px;'
            : 'width: 100px; height: 100px;'
        "
      >
        <v-img :src="blog.cover" width="100%" height="100%" />
      </div>
      <div
        class="ma-2 overflow-hidden text-ellipsis text-line-2 dark-font-color"
        :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
      >
        {{ extractContent(blog.html) }}
      </div>
    </v-card-subtitle>
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :loading="loading"
      :onClose="onCloseDialog"
      :onConfirm="onConfirmDelete"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import EditableAvatar from "../../components/EditableAvatar.vue";
export default {
  components: { ConfirmDeleteDialog, EditableAvatar },
  props: {
    blog: Object
  },
  data() {
    return {
      snackbar: false,
      errorMessage: null,
      deleteDialog: false,
      loading: false
    };
  },
  methods: {
    ...mapActions("blog", {
      deleteBlog: "deleteBlog"
    }),
    extractContent(s) {
      var span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    goToDetails() {
      this.$router.push({
        name: "blog-details",
        query: { _id: this.blog._id }
      });
    },
    editBlog() {
      this.$router.push({
        name: "edit-blog",
        query: { _id: this.blog._id }
      });
    },
    onDeleteBlog() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteBlog({ _id: this.blog._id })
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          if (error.response) {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          } else {
            this.snackbar = true;
            this.errorMessage = error;
          }
        });
    },
    goToAgencyProfile(agency) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: agency }
      });
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month ago`;
      if (month) return `${month} months ago`;
      if (days == 1) return `1 day ago`;
      if (days) return `${days} days ago`;
      if (hours == 1) return `1 hours ago`;
      if (hours) return `${hours} hours ago`;
      if (mins == 1) return `1 min ago`;
      if (mins) return `${mins} min ago`;
      return `less than 1 min ago`;
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  }
};
</script>
<style scoped></style>
