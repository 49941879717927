<style>
.left-popup-setting .v-image__image--cover {
  filter: brightness(0.2) !important;
}
.left-popup-setting .app-medium-font.white--text {
  color: #000 !important;
  caret-color: #000 !important;
}
.left-popup-setting selected .list-item .v-image__image--cover {
  filter: brightness(0.2) !important;
}
.left-popup-setting .list-item:hover {
  background: #55eef3f8;
}
</style>

<template>
  <div class="feed-body">
    <div class="feed-contents">
      <div class="hidden-md-and-up w-100 px-3">
        <v-menu offset-y rounded="lg" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mb-2">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 left-popup-setting" style="width: 260px;">
            <search-list-card
              :title="category.title"
              :image="category.image"
              :image_selected="category.image_selected"
              v-for="category in categories"
              :key="category.title"
            />
          </v-list>
        </v-menu>
      </div>
      <div v-if="contents.length == 0 && !loading" class="mt-15 white--text">
        {{ $t("no_search_result") }}
      </div>
      <v-list
        class="pt-3 w-100"
        v-else-if="!loading"
        style="background-color: #0000;"
      >
        <v-list-item v-for="(item, index) in contents" :key="index">
          <v-list-item-title
            v-if="item.header"
            class="app-bold-font font-20 white--text"
            :class="{ 'mt-5': index != 0, 'mb-3': true }"
          >
            {{
              item.header == "Agency"
                ? $t("agency")
                : item.header == "Needs"
                ? $t("needs")
                : item.header == "Events"
                ? $t("events")
                : item.header == "Blogs"
                ? $t("blogs")
                : item.header
            }}
          </v-list-item-title>
          <v-list-item-title
            v-else-if="item.type == 'Agency' || item.type == 'Volunteer'"
            @click="selectUser(item)"
            class="list-user-item url-button bg-white pt-3 rounded-lg font-bold font-16 dark-gray-color mt-3"
          >
            <div>
              <editable-avatar
                :editable="false"
                :image="item.thumbnail ? item.thumbnail : item.image"
                :size="40"
                class="mr-2 ml-3"
              />
              {{ item.name }}
            </div>
            <v-divider class="mt-3" />
          </v-list-item-title>
          <v-list-item-title v-else-if="item.type == 'Need'">
            <agency-need-card :need="item" class="mb-3" />
          </v-list-item-title>
          <v-list-item-title v-else-if="item.type == 'Event'">
            <feed-event-card :_id="item._id" />
          </v-list-item-title>
          <v-list-item-title v-else-if="item.type == 'Blog'">
            <blog-card :blog="item" class="mb-3" />
          </v-list-item-title>
          <v-list-item-title v-else>
            {{ item }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-progress-circular indeterminate color="amber" v-else class="mt-15" />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import FeedEventCard from "../event_feeds/FeedEventCard.vue";
import AgencyNeedCard from "../agency/needs/AgencyNeedCard.vue";
import BlogCard from "../blogs/BlogCard.vue";
import SearchListCard from "./SearchListCard.vue";

export default {
  components: {
    EditableAvatar,
    FeedEventCard,
    AgencyNeedCard,
    BlogCard,
    SearchListCard
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      contents: [],
      categories: [
        {
          title: "All",
          image: require("@/assets/svg/search/icon_all.svg"),
          image_selected: require("@/assets/svg/search/icon_all_selected.svg")
        },
        {
          title: "Volunteer",
          image: require("@/assets/svg/search/icon_volunteer.svg"),
          image_selected: require("@/assets/svg/search/icon_volunteer_selected.svg")
        },
        {
          title: "Agency",
          image: require("@/assets/svg/search/icon_agency.svg"),
          image_selected: require("@/assets/svg/search/icon_agency_selected.svg")
        },
        {
          title: "Needs",
          image: require("@/assets/svg/search/icon_needs.svg"),
          image_selected: require("@/assets/svg/search/icon_needs_selected.svg")
        },
        {
          title: "Events",
          image: require("@/assets/svg/search/icon_events.svg"),
          image_selected: require("@/assets/svg/search/icon_events_selected.svg")
        },
        {
          title: "Blogs",
          image: require("@/assets/svg/search/icon_blogs.svg"),
          image_selected: require("@/assets/svg/search/icon_blogs_selected.svg")
        }
      ]
    };
  },
  methods: {
    ...mapActions("search", {
      getSearchContents: "getSearchContents",
      loadMore: "loadMoreNotifications"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onSearchContents() {
      this.loading = true;
      this.getSearchContents({
        key: this.$route.query.q,
        category: this.$route.query.category
      })
        .then(res => {
          this.loading = false;
          this.contents = res;
        })
        .catch(res => {
          this.loading = false;
          console.log(res.response.data.message);
        });
    },
    selectUser(item) {
      if (item.type == "Agency") {
        this.$router.push({
          name: "agency-profile-details",
          params: { id: item._id }
        });
      } else if (item.type == "Volunteer") {
        this.$router.push({
          name: "volunteer-profile-details",
          params: { id: item._id }
        });
      }
    }
  },
  computed: {
    ...mapGetters("notification", {
      notifications: "getAllNotifications"
    })
  },
  watch: {
    "$route.query": function() {
      this.onSearchContents();
    }
  },
  mounted() {
    this.onSearchContents();
  }
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
@media (max-width: 959px) {
  .feed-contents {
    padding-top: 4px;
  }
}
</style>
